import { NotFoundIcon } from 'ui-kit/icons/illustrations/NotFound';

import Button from 'ui-kit/button';
import { Links } from 'types/links';
import { ButtonType } from 'ui-kit/button/type';

import styles from './styles.module.scss';

const NotFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <h1 className={styles.errorCode}>404</h1>
        <div className={styles.informationContainer}>
          <p className={styles.headline}>Такой страницы нет</p>
          <br /> Похоже, вы потерялись или допустили опечатку, набирая адрес.
          <br /> А может, перешли по неисправной ссылке.
        </div>
        <Button
          type={ButtonType.button}
          className={styles.button}
          href={Links.organization}>
          На главную
        </Button>
      </div>
      <div className={styles.imageWrapper}>
        <NotFoundIcon />
      </div>
    </div>
  );
};

export default NotFound;
